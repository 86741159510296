<template>
  <section class="researcher-records-page">
    <header class="section-header">
      <div class="section-header__text">
        <h1 class="section-header__title">
          {{ $t('researcher.public.profile.records.header') }}
        </h1>
        <p>{{ $t('researcher.public.profile.records.description') }}</p>
      </div>
    </header>
    <SearchPaginatedList
      :is-initial-loading="isInitialLoading"
      :is-searching="isSearching"
      :query="searchQuery"
      :page-data="records"
    >
      <template #search-bar="{buttonAttrs, inputAttrs}">
        <div class="f-field-search">
          <button
            class="f-field-search__submit"
            v-bind="buttonAttrs"
          />
          <input
            v-model.trim="searchQuery"
            :placeholder="$t('search.records')"
            type="search"
            class="f-field-search__input f-field-search__input--card"
            v-bind="inputAttrs"
          >
        </div>
      </template>
      <template #list-header="{ isLoading }">
        <AdvancedOptions>
          <template #main>
            <MultiStateCounter
              :is-loading="isLoading"
              :is-searching="isSearching"
              :count="records ? records.totalItems : 0"
              class="tab-navigation__counter"
            />
          </template>
          <template #options>
            <p class="h4">
              {{ $t('researcher.public.profile.records.fields.types.label') }}
            </p>
            <RecordTypesFilter
              v-model="typeFilters"
              :columns="3"
            />
            <p class="h4">
              {{ $t('researcher.public.profile.records.fields.date.label') }}
            </p>
            <div class="date-filters">
              <DateInput
                :value="start || {}"
                :field-name="'from'"
                :show-label="true"
                :error="null"
                @input="$router.push({ query: { ...$route.query, page: 1, start: objectDateToString($event) } })"
              />
              <DateInput
                :value="end || {}"
                :field-name="'to'"
                :show-label="true"
                :error="null"
                @input="$router.push({ query: { ...$route.query, page: 1, end: objectDateToString($event) } })"
              />
            </div>
          </template>
        </AdvancedOptions>
      </template>
      <template #list-skeleton>
        <RecordSkeleton
          :count="perPage"
          class="card-list"
        />
      </template>
      <template #list>
        <ListRecords
          :records="records.items"
          :compact="true"
          :highlight-titles="q === ''"
          @deleted="refreshPage({ keepPage: true, isDeletion: true })"
        />
      </template>
      <template #pagination>
        <PaginationBar
          :current-page="currentPage"
          :total-items="records.totalItems"
          :items-per-page="perPage"
        />
      </template>
      <template #empty-state="{ isAlwaysEmpty }">
        <RecordEmptyState>
          <template #title>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.records.empty.state.always.empty.header') }}
            </template>
            <template v-else-if="emptyStateSearchQuery">
              {{ $t('researcher.public.profile.records.empty.state.search.results.header', { query: emptyStateSearchQuery }) }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.records.empty.state.filter.results.header') }}
            </template>
          </template>
          <template #description>
            <template v-if="isAlwaysEmpty">
              {{ $t('researcher.public.profile.records.empty.state.always.empty.description') }}
            </template>
            <template v-else-if="emptyStateSearchQuery">
              {{ $t('researcher.public.profile.records.empty.state.search.results.description') }}
            </template>
            <template v-else>
              {{ $t('researcher.public.profile.records.empty.state.filter.results.description') }}
            </template>
          </template>
        </RecordEmptyState>
      </template>
    </SearchPaginatedList>
  </section>
</template>

<script>
import PaginationBar from '@/components/utils/PaginationBar.vue'
import RecordEmptyState from '@/components/empty-states/Record.vue'
import RecordSkeleton from '@/components/loading-skeletons/Record.vue'
import { getRecords } from '@/api/researchers'
import SearchPaginatedList from '@/components/utils/SearchPaginatedList.vue'
import ListRecords from '@/components/search/results/ListRecords.vue'
import AdvancedOptions from '@/components/utils/AdvancedOptions.vue'
import RecordTypesFilter from '@/components/records/filters/RecordTypes.vue'
import MultiStateCounter from '@/components/utils/MultiStateCounter.vue'
import { handlePageReload } from '@/utils/pagination-helpers'
import DateInput from '@/components/utils/fields/DateInput.vue'
import DateHelpers from '@/mixins/DateHelpers'

export default {
  components: {
    PaginationBar,
    RecordEmptyState,
    RecordSkeleton,
    SearchPaginatedList,
    ListRecords,
    AdvancedOptions,
    RecordTypesFilter,
    MultiStateCounter,
    DateInput
  },
  mixins: [DateHelpers],
  beforeRouteUpdate (to, from, next) {
    this.records = undefined
    if (this.searchTimeout) clearTimeout(this.searchTimeout)
    this.isSearching = true
    this.searchTimeout = setTimeout(async () => {
      this.emptyStateSearchQuery = to.query.q
      if (to.query.start) to.meta.start = this.dateStringToObjectString(to.query.start)
      if (to.query.end) to.meta.end = this.dateStringToObjectString(to.query.end)
      this.currentPage = Number(to.query.page) || 1
      const records = await getRecords({
        userId: this.userId,
        page: this.currentPage,
        perPage: this.perPage,
        q: to.query.q,
        recordTypes: this.typeFilters,
        start: to.meta.start ? to.meta.start : undefined,
        end: to.meta.end ? to.meta.end : undefined
      })
      this.records = this.records ?? records
      this.isSearching = false
    }, 500)
    localStorage.setItem('perPage', to.query.perPage)
    next()
  },
  props: {
    userId: {
      type: String,
      required: true
    },
    page: {
      type: Number,
      required: true
    },
    q: {
      type: String,
      default: ''
    },
    type: {
      type: Array,
      default: () => []
    },
    start: {
      type: Object,
      default: null
    },
    end: {
      type: Object,
      default: null
    },
    perPage: {
      type: Number,
      default: Number(localStorage.getItem('perPage')) || 10
    }
  },
  data () {
    return {
      isInitialLoading: true,
      isSearching: false,
      currentPage: this.page,
      records: undefined,
      searchQuery: this.q,
      searchTimeout: undefined,
      typeFilters: this.type
    }
  },
  metaInfo: function () {
    return {
      title: this.$t('title'),
      meta: [{ name: 'description', content: this.$t('description') }]
    }
  },
  watch: {
    async searchQuery (newValue, oldValue) {
      if (newValue === oldValue) return

      const query = { ...this.$route.query, q: newValue }
      if (newValue === '') {
        delete query.q
      }
      delete query.page
      await this.$router.replace({ query })
    },
    async typeFilters (newValue) {
      const query = { ...this.$route.query, type: newValue }
      delete query.page
      await this.$router.replace({ query })
    }
  },
  beforeDestroy () {
    clearTimeout(this.searchTimeout)
  },
  async mounted () {
    this.records = await getRecords({
      userId: this.userId,
      page: this.currentPage,
      perPage: this.perPage,
      q: this.q,
      recordTypes: this.typeFilters,
      start: this.start ? this.start : undefined,
      end: this.end ? this.end : undefined
    })
    this.isInitialLoading = false
    // purposefully makes search query non-reactive to display on empty state
    this.emptyStateSearchQuery = this.searchQuery
  },
  methods: {
    async refreshPage ({ keepPage = false, isDeletion = false }) {
      // called after delete
      await handlePageReload({
        keepPage,
        isDeletion,
        numberOfItemsInPage: this.records.items.length,
        router: this.$router,
        getItems: async () => {
          this.records = await getRecords({
            userId: this.userId,
            page: this.currentPage,
            perPage: this.perPage,
            q: this.q,
            recordTypes: this.typeFilters,
            start: this.start ? this.start : undefined,
            end: this.end ? this.end : undefined
          })
        },
        component: this
      })
    }
  },
  i18n: {
    messages: {
      pt: {
        title: 'Publicações',
        description: 'Uma lista com todos as publicações deste autor'
      },
      en: {
        title: 'Publications',
        description: 'A list with all publications of this author'
      }
    }
  }
}
</script>

<style lang="scss">
.date-filters {
  margin-right: 8rem;
  .f-field {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .f-field__label {
      width: 3rem;
    }
    .f-inline-group {
      flex-grow: 1;
    }
  }
}
</style>
